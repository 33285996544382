<template>
    <div v-on="$listeners" :key="account.email" class="account-item">
        <div class="account-item__content">
            <avatar
                class="account-item__avatar"
                :name="account.email.charAt(0)"
                :last-name="account.email.charAt(1)"
                size="xs"
            />
            <div>
                <h6 class="account-item__name" v-if="account.name">{{ account.name }}</h6>
                <p class="account-item__email mb-0">{{ account.email }}</p>
            </div>
        </div>
        <div class="account-item__actions">
            <button class="account-item__delete-button" title="Delete account" @click.stop="deleteAccount(account.email)">
                <span class="account-item__delete-icon fa fa-trash"></span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import Avatar from '@/components/ui/Avatar';

export default {
    name: 'AccountItem',
    components: { Avatar },
    props: {
        account: {
            type: Object,
            default: null,
            required: true,
        },
    },
    methods: {
        ...mapActions('deviceAccounts', ['deleteAccount']),
    },
};
</script>

<style lang="scss">
.account-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    transition: 300ms background;
    border-radius: 0.25rem;
    position: relative;

    &:hover {
        background: #ebf2fd;
        cursor: pointer;
    }

    &::after {
        content: "";
        height: 0;
        left: 0;
        right: 0;
        position: absolute;
        border-bottom: 1px solid #f0f0f0;
        top: 0;
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__avatar {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 50px;
        width: 50px;
        margin-right: 13px;

        &--has-icon {
            color: #838282;
            font-size: 1.1rem;
        }
    }

    &__name {
        font-size: .95rem;
        margin-bottom: 2px;
    }

    &__email {
        font-size: .85rem;
        line-height: 1.33;
    }

    &__delete-button {
        color: #adb2bc;
        background-color: transparent;
        border: none;
        padding: 13px;
    }

    &__delete-icon {
        color: #adb2bc;
    }
}
</style>
