<template>
    <ui-modal
        ref="confirmationModal"
        centered
        :title="$t(this.title)"
        @hidden="resetForm()"
    >
        <div>
            <p>{{ $t(this.message) }}</p>
            <ui-input
                id="email"
                v-model="formData.email"
                name="email"
                type="email"
                disabled
                :label="$t('Email')"
                :field="v$.formData.email"
            />
        </div>
        <template #footer>
            <ui-button variant="secondary" :disabled="loading" @click="$refs.confirmationModal.close()">
                {{ $t('Cancel') }}
            </ui-button>
            <ui-button :loading="loading" @click="sendPasswordResetEmail">
                {{ $t(buttonText) }}
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators';

import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiModal from '@/components/ui/modal/Modal.vue';

export default {
    name: 'RecoveryPasswordModal',
    components: {
        UiButton,
        UiInput,
        UiModal,
    },
    props: {
        email: {
            type: String,
            default: '',
            required: false,
        },
        isUpdating: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            formData: {
                email: '',
            },
            loading: false,
        };
    },
    computed: {
        title() {
            return this.isUpdating ? 'Update password' : 'Recovery password';
        },
        message() {
            return this.isUpdating ? 'We will send you an email to update your password.' : 'You will receive an email to recover your password.';
        },
        buttonText() {
            return this.isUpdating ? 'Continue' : 'Send';
        },
    },
    watch: {
        email(value) {
            this.formData.email = value;
        },
    },
    validations() {
        return {
            formData: {
                email: {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('email') }), required),
                    email: helpers.withMessage(this.$t('Email invalid'), email),
                },
            },
        };
    },
    mounted() {
        if (this.email) {
            this.formData.email = this.email;
        }
    },
    methods: {
        close() {
            this.$refs.confirmationModal.close();
        },
        show() {
            this.$refs.confirmationModal.show();
        },
        resetForm() {
            this.formData.email = this.email;
        },
        async sendPasswordResetEmail() {
            try {
                this.v$.$touch();
    
                if (this.v$.$invalid) {
                    return;
                }

                this.loading = true;
                const config = { auth: { username: this.formData.email, password: '' } }
                await this.axiosAccount.post('/api/accounts/send/recovery', {}, config)
                this.$toasted.global.successMessage(this.$t('Mail sent successfully'));
                this.close();
            } catch(error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
