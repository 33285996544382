'use strict';

const google = {
    methods: {
        socialNetWorkSucess(payload) {
            axiosAccount.post(`/api/accounts/verifysn?name=${payload.name}`, {
                _tk: payload._tk,
                site_id: this.user.siteId,
                uri: window.location.href
            })
            .then(response => response.data)
            .then(response => {
                this.user.email = response.email;
                let phone_url = `/phone-verification?email=${this.user.email}&site_id=${this.user.siteId}&redirect_url=${this.user.redirect_url}&social=true`;
                let documents_url = `/documents?email=${this.user.email}&site_id=${this.user.siteId}&redirect_url=${this.user.redirect_url}&from=login`;
                localStorage.setItem('accountUrl', response.redirect_url);
                if (!response.redirect == 'confirmated') {
                    this.loginErrors.emailConfirmation = true;
                } else if (response.redirect == 'register-phone') {
                    this.$refs.formRegisterPhone.showModal = true;
                    this.$refs.formRegisterPhone.step = 0;
                } else if (response.redirect == 'accept-tcpp') {
                    this.$refs.formTCPP.open = true;
                    this.$refs.formTCPP.step = 3;
                } else if (response.redirect == 'documents-phone') {
                    this.$router.push(`${documents_url}&next=${Buffer.from(phone_url).toString('base64')}`);
                } else if (response.redirect == 'phone') {
                    this.$router.push(phone_url);
                } else if (response.redirect == 'documents') {
                    this.$router.push(documents_url);
                } else {
                    window.location.replace(response.redirect_url);
                }
            })
            .catch(err => window.console.error(err));
        },
        passwordStep(newStep) {
            if (newStep === 0) {
                this.$router.push(`/phone-verification?email=${this.user.email}&site_id=${this.user.siteId}&redirect_url=${this.user.redirect_url}&social=true`);
            } else if (newStep === 3) {
                window.location.replace(localStorage.getItem('accountUrl'));
            } else {
                this.step = 2;
                this.validatedEmail = true;
                setTimeout(() => {
                    document.getElementById('userPassword').focus();
                }, 800);
            }
        }
    }
};

export default google;