<template>
    <button class="web-authn-button" @click="login">
        <i class="fas fa-fingerprint"></i>
    </button>
</template>

<script>
import { mapActions } from 'vuex';
import base64url from 'base64url';

import webViewMixin from '@/plugins/mixin/web-view.mixin';

export default {
    name: 'LoginWebAuthn',
    mixins: [webViewMixin],
    data() {
        return {
            loading: false,
        }
    },
    props: {
        email: {
            type: String,
            required: false,
            default: null

        }
    },
    methods: {
        ...mapActions('deviceAccounts', ['addAccount']),
        async getAuthenitcationOptions() {
            const config = { auth: { username: this.email, password: '' } } 
            const { data } = await this.axiosAccount.get('/api/biometric-auth/authentication/options', config);
            const options = data.options;

            const credential_options = {
                challenge: Uint8Array.from(options.challenge, c => c.charCodeAt(0)),
                rpId: options.rpId,
                userVerification: options.userVerification,
                allowCredentials: [],
                extensions: { uvm: true, loc: false },
                timeout: options.timeout
            };
            return credential_options;
        },
        async login() {
            try {
                const credential_options = await this.getAuthenitcationOptions();

                const credential = await navigator.credentials.get({ publicKey: credential_options });

                const format_credential = {
                    id: credential.id,
                    rawid: base64url.encode(credential.rawId),
                    response: {
                        authenticatorData: base64url.encode(credential.response.authenticatorData),
                        signature: base64url.encode(credential.response.signature),
                        clientDataJSON: base64url.encode(credential.response.clientDataJSON),
                        userHandle: base64url.encode(credential.response.userHandle)
                    },
                    type: credential?.type,
                    authenitcatorAttachment: credential.authenticatorAttachment
                };

                const format_token = `${this.email}:${JSON.stringify(format_credential)}`;
                const token = Buffer.from(format_token).toString('base64');
                const config = { headers: { 'Authorization': `Basic ${token}` }};

                const { data } = await this.axiosAccount.post('/api/login/webauthn', { "token": " "}, config);
                let { redirect_url } = data;
                const webview = this.$route.query.webview;

                if (webview) {
                    redirect_url = this.formatWebviewRedirectUrl(redirect_url);
                }

                this.addAccount({ email: data.email });
                this.$toasted.global.successMessage('Login successfully.');
                window.location.replace(redirect_url);
            } catch (error) {
                this.$toasted.global.errorMessage(error.response.data.message);
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss">
.web-authn-button {
    align-items: center;
    background-color: transparent;
    border: 1px solid #dadce0;
    border-radius: 4px;
    color: $accounts-primary;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}
</style>