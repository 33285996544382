<template>
    <div v-if="itsEnvia" class="tracking-alert">
        {{ $t('Do you need to track a shipment?') }}
        <a class="tracking-alert__link" v-sanitize-href="trackingUrl" target="_blank">
            {{ $t('Access here') }}
        </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'tracking-alert',
    computed: {
        ...mapGetters('ui', ['sites', 'language']),
        itsEnvia() {
            const { site_id } = this.$route.query;
            const envia = this.sites.find(site => site.name.toLowerCase() === 'envia');

            if (envia && site_id) {
                return site_id === envia?.site_id;
            }

            return false;
        },
        trackingUrl() {
            return `${process.env.VUE_APP_LANDING_ENVIA_URL}/${this.language}/${this.$t('tracking')}`;
        },
    },
};
</script>

<style lang="scss">
.tracking-alert {
    background-color: #F7F7F7;
    border-radius: 8px;
    color: #404042;
    line-height: 24px;
    margin-bottom: 24px;
    padding: 8px;

    &__link {
        color: $accounts-primary;
        font-weight: 600;

        &:hover {
            color: $accounts-primary-600;
            text-decoration: none;
        }
    }
}
</style>
