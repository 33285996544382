<template>
    <div class="login-form">
        <form @submit.prevent="handleSubmit()">
            <template v-if="step === 0">
                <account-list class="mb-1" @selectAccount="selectAccount" @useAnotherAccount="useAnotherAccount" />
            </template>
            <div class="mb-3" :class="step === 1 ? '' : 'd-none'">
                <ui-input
                    id="email"
                    v-model="formData.email"
                    name="email"
                    type="text"
                    placeholder="email@example.com"
                    :label="$t('Email')"
                    :field="v$.formData.email"
                    @change="resetErrors()"
                />
            </div>
            <div :class="`${step === 2 ? '' : 'd-none'}`">
                <div class="login-form__selected-email-container">
                    <p class="login-form__selected-email">
                        <span class="login-form__email-icon fas fa-check-circle"></span>
                        {{ formData.email }}
                    </p>
                    <button class="login-form__change-email-button" type="button" @click="resetForm()">
                        <span class="fas fa-times"></span>
                    </button>
                </div>
                <ui-input
                    id="password"
                    v-model="formData.password"
                    name="password"
                    type="password"
                    placeholder="********"
                    class="mb-2"
                    :label="$t('Password')"
                    :field="v$.formData.password"
                    @change="resetErrors()"
                />
                <div class="text-left">
                    <b-button
                        variant="link"
                        type="button"
                        class="login-form__forgot-password"
                        @click="openResetPasswordModal()"
                    >
                        {{ $t('Forgot password?') }}
                    </b-button>
                </div>
                <vue-recaptcha
                    class="w-100"
                    ref="recaptchaComponent"
                    size="invisible"
                    @verify="login"
                    :sitekey="CONST.CAPTCHACODE"
                    :loadRecaptchaScript="true"
                />
            </div>
            <div class="d-flex">
                <ui-button v-if="step !== 0" id="login-continue" :loading="validating" class="w-100 mb-3" type="submit" variant="primary">
                    {{ $t('Continue') }}
                </ui-button>
            </div>
        </form>
        <b-alert class="mt-2" fade :show="!!loginError" variant="danger">
            {{ loginError }}
        </b-alert>
        <b-alert class="mt-2" fade :show="requireUpdatePassword" variant="warning">
            <p class="mb-1">
                {{ $t('To login you need to update your password, to do this you can click on the following link:') }}
            </p>
            <b-button
                variant="link"
                type="button"
                class="login-form__forgot-password mb-0"
                @click="openResetPasswordModal()"
            >
                {{ $t('Update password') }}
            </b-button>
        </b-alert>
        <recovery-password-modal ref="recoveryPasswordModal" :email="formData.email" :is-updating="requireUpdatePassword" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength, helpers } from '@vuelidate/validators';

import AccountList from '@/components/login/AccountList.vue';
import RecoveryPasswordModal from '@/components/login/RecoveryPasswordModal.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';

import googleMixin from '@/plugins/mixin/google.mixin';
import iframeMixin from '@/plugins/mixin/iframe.mixin';
import webViewMixin from '@/plugins/mixin/web-view.mixin';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'LoginForm',
    components: {
        AccountList,
        RecoveryPasswordModal,
        UiButton,
        UiInput,
        VueRecaptcha,
    },
    mixins: [googleMixin, iframeMixin, webViewMixin],
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            emailConfirmation: false,
            formData: {
                email: '',
                password: '',
            },
            vuelidateExternalResults: {
                formData: {
                    email: [],
                    password: [],
                },
            },
            validating: false,
            loginError: null,
            requireUpdatePassword: false,
            showPassword: false,
            step: 1,
            user: {
                siteId: null,
                redirect_url: null,
            },
        };
    },
    computed: {
        rules() {
            const rules = { formData: {} };

            if (this.step === 1) {
                rules.formData.email = {
                    required: helpers.withMessage(this.$t('validations.required', { field: this.$t('email') }), required),
                    email: helpers.withMessage(this.$t('Email invalid'), email),
                    $autoDirty: true,
                };
            }

            if (this.step === 2) {
                rules.formData.password = {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('password') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(6)),
                    $autoDirty: true,
                };
            }

            return rules;
        },
        ...mapState('AccountLogin', ['userData']),
        ...mapGetters('deviceAccounts', ['accounts']),
    },
    watch: {
        step(value) {
            if (value !== 0 && this.$refs.loginForm) {
                this.$refs.loginForm.reset();
            }
        },
        accounts(value) {
            if (!value.length) {
                this.useAnotherAccount();
            }
        }
    },
    validations() {
        return this.rules;
    },
    beforeMount() {
        if (this.$route.query.site_id) {
            this.user.siteId = this.$route.query.site_id;
        }

        if (this.$route.query.email) {
            this.formData.email = this.$route.query.email;

            if (!this.$route.query.recovery_password) {
                setTimeout(() => {
                    this.handleSubmit();
                }, 800);
            } else {
                this.$toasted.global.successMessage(this.$t('Password recovered successfully'));
            }
        } else if (this.accounts.length) {
            this.selectAccount(this.accounts[0].email);
        }

        if (this.$route.query.redirect_url) {
            this.user.redirect_url = this.$route.query.redirect_url;
        }

        if (this.$route.query.confirmation) {
            this.emailConfirmation = true;
        }
    },
    mounted() {
        const { message } =  this.$route.query;

        if (message) {
            this.$toasted.global.errorMessage(this.$t(message));
        }
    },
    methods: {
        ...mapActions('deviceAccounts', ['addAccount']),
        resetForm() {
            if (this.accounts.length) {
                this.step = 0;
            } else {
                this.step = 1;
            }

            this.formData.email = '';
            this.formData.password = '';
            this.focustInput('email');
            this.$emit('update:showFinger', { status: false, email: ''});
        },
        resetErrors() {
            this.loginError = null;
            this.requireUpdatePassword = false;
        },
        useAnotherAccount() {
            this.step = 1;
            this.formData.email = '';
            this.focustInput('email');
            this.$emit('update:showFinger', { status: false, email: ''});
        },
        selectAccount(email) {
            this.formData.email = email;
            this.validateEmail();
        },
        openResetPasswordModal() {
            this.$refs.recoveryPasswordModal.show();
        },
        focustInput(inputName) {
            setTimeout(() => {
                const input = document.getElementById(inputName);
                if (input) input.focus();
            }, 800);
        },
        formatRedirectUrl(redirect_url) {
            if (!redirect_url.startsWith('/')) {
                const formatted_url = new URL(redirect_url);
                const current_host = window.location.host;
                const webview = this.$route.query.webview;

                if (webview) {
                    formatted_url.searchParams.set('webview', webview);
                }

                if (formatted_url.host === current_host && formatted_url.pathname !== '/authentication') {
                    return `${formatted_url.pathname}${formatted_url.search}`
                }
            }
        
            return redirect_url;
        },
        async handleSubmit() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                return;
            }

            if (this.step === 1) {
                this.resetErrors();
                this.validateEmail();
            }

            if (this.step === 2) {
                if (this.isRunningInWebView || !recaptchaUtils.isAvailable()) {
                    this.login('');
                    return;
                }

                this.validating = true;
                this.$refs.recaptchaComponent.reset();
                this.$refs.recaptchaComponent.execute();
            }
        },
        async validateEmail() {
            try {
                this.validating = true;

                const config = { auth: { username: this.formData.email, password: '' } }
                const { data } = await this.axiosAccount.get('/api/accounts/validate/email', config)
                const { password, webauthn } = data;
                const showWebAuthnLogin = await this.deviceRegistered();

                if(webauthn && showWebAuthnLogin) {
                    this.$emit('update:showFinger', { status: true, email: this.formData.email});
                }

                if (!password)  {
                    this.requireUpdatePassword = true;
                    return;
                }

                this.step = 2;
                this.focustInput('password');
            } catch (error) {
                if (error.response.status === 404) {
                    const errors = { formData: { email: [this.$t('This account does not exist')] } };
                    Object.assign(this.vuelidateExternalResults, errors)

                    return;
                }

                this.showError(error);
            } finally {
                this.validating = false;
            }
        },
        async deviceRegistered() {
            let device = navigator.userAgent;
            let payload = { device: device };
            let config = { auth: { username: this.formData.email, password: '' } };
            const { data } = await this.axiosAccount.post('/api/biometric-auth/device/exist', payload, config);
            return data;
        },
        async login(response) {
            try {
                this.validating = true;
    
                const token = `${this.formData.email}:${this.formData.password}`;
                const encodedToken = Buffer.from(token).toString('base64');
                const config = { headers: { 'Authorization': 'Basic ' + encodedToken } };
                const payload = { token: response };

                const { data } = await this.axiosAccount.post('/api/login', payload, config);
                let { redirect_url, twof, atfa } = data;
                const webview = this.$route.query.webview;

                this.addAccount({ email: this.formData.email })

                if (webview) {
                    redirect_url = this.formatWebviewRedirectUrl(redirect_url);
                }

                if (twof === false && !this.itsAnIframe && atfa === true) {
                    const query = { redirect_url };
                    const { site_id, lang, webview } = this.$route.query;

                    if (site_id) {
                        query.site_id = site_id;
                    }

                    if (lang) {
                        query.lang = lang;
                    }

                    if (webview) {
                        query.webview = webview;
                    }

                    sessionStorage.setItem('tfa_expiration', this.$moment().add(5, 'minutes'));
                    this.$router.push({ name: 'twoFactorAuthentication', query })
                    return;
                }

                redirect_url = this.formatRedirectUrl(redirect_url);

                redirect_url.startsWith('/')
                        ? this.$router.push(redirect_url)
                        : window.location.replace(redirect_url);
            } catch(error) {
                const status = error?.response?.status;
                const message = error?.response?.data?.message;

                if (status === 401) {
                    const errors = { formData: { password: [message] } };
                    Object.assign(this.vuelidateExternalResults, errors)

                    return;
                }

                if (status === 403) {
                    this.loginError = message;
                    return;
                }

                this.showError(error);
            } finally {
                this.validating = false;
            }
        },
    },
};
</script>

<style lang="scss">
.login-form {
    &__selected-email-container {
        background-color: #F2F6F8;
        border: 2px solid #F2F6F8;
        border-radius: 10px;
        margin-bottom: 25px;
        padding: 13px 30px;
        position: relative;
        text-align: center;
    }
    
    &__selected-email {
        color: #5E5E5E;
        font-size: 16px;
        line-height: 1 !important;
        margin-bottom: 0;
    }

    &__email-icon {
        color: #6DBB1A;
    }

    &__change-email-button {
        background-color: transparent;
        border: none;
        font-size: 15px;
        height: 20px;
        padding: 0;
        position: absolute;
        right: 10px;
        top: calc(50% - 10px);
        width: 20px;
        color: rgba(#787F84, 0.60);
    }

    &__forgot-password {
        color: $accounts-secondary !important;
        font-weight: 500 !important;
        margin-bottom: 20px;
        padding: 0 !important;
        text-align: left;
    }
}
</style>
