<template>
    <div class="login-page">
        <logos-container />
        <tracking-alert />
        <h1 class="login-page__title">{{ $t('Login') }}</h1>
        <p class="login-page__text">{{ $t('Welcome, please enter your credentials') }}</p>
        <login-form ref="login" :showFinger.sync="showFinger"/>
        <template v-if="!itsAnIframe">
            <div class="login-page__social-separator">
                <span class="login-page__social-separator-text">
                    {{ $t('Or') }}
                </span>
            </div>
            <div class="login-page__social-buttons">
                <google-login-button :type="showFinger.status ? 'icon' : null" />
                <web-authn-button v-if="showFinger.status" :email="showFinger.email" />
            </div>
        </template>
        <p class="login-page__signup">
            {{ $t("Do not have an account yet?") }}
            <router-link :to="{ path: '/signup', query: { ...$route.query }}" class="login-page__link">
                {{ $t('Signup') }}
            </router-link>
        </p>
    </div>
</template>
<script>
import GoogleLoginButton from '@/components/ui/GoogleLoginButton.vue';
import LoginForm from '@/components/forms/Login.vue';
import LogosContainer from '@/components/ui/LogosContainer.vue';
import TrackingAlert from '@/components/envia/TrackingAlert.vue';
import WebAuthnButton from '@/components/authentication/webauthn/WebAuthnButton.vue';

import iframeMixin from '@/plugins/mixin/iframe.mixin';

export default {
    name: 'login-page',
    components: {
        GoogleLoginButton,
        LoginForm,
        LogosContainer,
        TrackingAlert,
        WebAuthnButton,
    },
    mixins: [iframeMixin],
    data() {
        return {
            showFinger: {
                status: false,
                email: ''
            },
        };
    }
};
</script>

<style lang="scss">
.login-page {
    text-align: left;

    &__logo {
        margin-bottom: 35px;
    }

    &__title {
        font-size: 35px;
        font-weight: 600;
    }

    &__text {
        color: #787F84;
        font-size: 16px;
        margin-bottom: 35px;
    }

    &__social-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: center;
        width: 100%;
    }

    &__social-separator {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        position: relative;
    }

    &__social-separator-text {
        font-size: 14px;
        color: #9093A1;
        font-weight: 500;
        padding-left: 10px;
        padding-right: 10px;

        &::after {
            background-color: #f0f0f0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            top: calc(50% - 1px);
            width: calc(50% - 18px);
        }

        &::before {
            background-color: #f0f0f0;
            content: '';
            height: 1px;
            position: absolute;
            right: 0;
            top: calc(50% - 1px);
            width: calc(50% - 18px);
        }
    }

    &__signup {
        color: #9093A1;
        font-size: 16px;
        font-weight: 400;
        margin-top: 42px;
    }

    &__link {
        color: #004C74;
        font-weight: 600;
    }

    &__logos-container {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;

        @media (min-width: 992px) {
            justify-content: flex-start;
        }
    }

    &__envia-logos {
        display: flex;
        width: fit-content;
    }

    &__logo-img {
        width: 100%;
        max-width: 100px;
        height: 100%;
        max-height: 50px;
    }

    &__divider {
        width: 1px;
        height: 30px;
        background: #aaa;
        margin-left: 10px;
    }
}
</style>
