<template>
    <div class="account-list">
        <account-item
            v-for="account in accounts"
            :key="account.email"
            :account="account"
            @click="$emit('selectAccount', account.email)"
        />
        <div class="account-list__item account-item" @click="$emit('useAnotherAccount')">
            <div class="account-item__content">
                <div class="account-item__avatar account-item__avatar--has-icon">
                    <span class="fas fa-user-plus"></span>
                </div>
                {{ $t('Use another account') }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AccountItem from '@/components/login/AccountItem.vue';

export default {
    name: 'AccountList',
    components: { AccountItem },
    computed: {
        ...mapGetters('deviceAccounts', ['accounts']),
    },
};
</script>

<style lang="scss">
.account-list {    
    &__item {
        padding: 3px 10px !important;
    }
}
</style>
